/**
 * This module declares the main Amplify object
 * Exports a "fake" reducer to be used in the stored for acquiring the object
 */

import { Auth, API, PubSub, Amplify } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

// This is kinda ugly but probably will have no other option here - we'll see
//const {REACT_APP_API_GATEWAY_URL_DEV,REACT_APP_API_GATEWAY_URL, NODE_ENV} = process.env;
const { REACT_APP_API_GATEWAY_URL } = process.env;
//const development = NODE_ENV === "development";
const gw_url = REACT_APP_API_GATEWAY_URL;

const authOptions = {
	Auth: {
		region: 'us-east-1',
		userPoolId: 'us-east-1_eJ9lP0WaW',
		userPoolWebClientId: '7mbtgro0ai4e7kv48spi1tlfnt',
		identityPoolId: "us-east-1:608a7a45-6590-4751-80e8-02b98a4e7bd5"
	},
	API: {
		endpoints: [
			{
				name: "IO_API",
				endpoint: gw_url,
				custom_header: async () => {
					return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`};
				}
			}
		]
	}
};

Amplify.configure(authOptions);
Auth.configure();
Amplify.Logger.LOG_LEVEL = process.env.NODE_ENV === "development" ? "DEBUG" : "";

Amplify.addPluggable(new AWSIoTProvider({
	aws_pubsub_region: "eu-west-1",
	aws_pubsub_endpoint: "wss://asfjfwm1u8ru-ats.iot.eu-west-1.amazonaws.com/mqtt"
}));

export {API, PubSub, Auth};