import React from "react";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { FleetPage, DevicePage, DevicesPage } from "@lifepowr/components/src/modules";
import { Header, NavBar } from "@lifepowr/components/src/components-core";
import { Fleets } from "@lifepowr/components/src/components";

import PolicyAuth from "@lifepowr/components/src/components/PolicyAuth";

import { legacy } from "@lifepowr/components";

const { Bones } = legacy;

function Routing() {
	const { search } = useLocation();
	const to = `:deviceName/general-status${search}`;

	return (
		<div className="super-container">
			<NavBar />
			<Switch>
				{/*<Route exact path='/:fleetOption/devices/:deviceName/battery-status' component={DevicePage} />
				<Route exact path='/:fleetOption/devices/:deviceName/io-settings' component={DevicePage} />
				<Route exact path='/:fleetOption/devices/:deviceName/device-details' component={DevicePage} />*/}

				{/*<Redirect exact from="/:fleetOption/devices/:deviceName/battery-status/" to="/devices/:battery-status/monitoring" />*/}
				<Route exact path='/:fleetOption/'>
					<FleetPage />
				</Route>
				<Route exact path='/:fleetOption/devices'>
					<DevicesPage />
				</Route>
				<Redirect exact from="/:fleetOption/devices/:deviceName" to={to} />
				{/*<Route exact path='/:fleetOption/devices/:deviceName/:pathName'>
					<DevicePage />
				</Route>*/}
				<Route path='/:fleetOption/devices/:deviceName/:pathName/:batteryPathName?'>
					<DevicePage />
				</Route>
				{/*<Route exact path='/:fleetOption/devices/:deviceName/battery-status/control' component={DevicePage} />
				<Route exact path='/:fleetOption/devices/:deviceName/battery-status/cells' component={DevicePage} />*/}
			</Switch>
		</div>
	);
}

function Footer(props){
	return (
		<div>Lifepowr © {(new Date()).getFullYear()} </div>
	);
}

function Navigation(props){
	return (
		<NavBar />
	);
}

function LandingPage() {

	const base = {
		Header,
		Navigation,
		Footer,
	};
	const { Navigation: _, ...baseFleets } = base;

	const routes = {
		'/': {
			components: {
				...baseFleets,
				Content: Fleets,
			},
			exact: true,
		},
		'/:fleetOption': {
			components: {
				...base,
				Content: FleetPage,
			},
			exact: true,
		},
		'/:fleetOption/devices': {
			components: {
				...base,
				Content: DevicesPage,
			},
			exact: true,
		},
		'/:fleetOption/devices/:deviceName/:pathName': {
			components: {
				...base,
				Content: DevicePage,
			},
		},
	};

	const redirects = [
		{
			from: '/:fleetOption/devices/:deviceName',
			to: ':deviceName/general-status',
			exact: true
		},
	];

	/*
	<Header />
			<Switch>
				<Route exact path='/' component={Fleets} />
				<Route path='/:fleetOption' component={Routing} />
			</Switch>
	 */

	return (
		<PolicyAuth>
			<Bones routes={routes} redirects={redirects}/>
		</PolicyAuth>
	);
}

export default LandingPage;

